import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import TeamOne from "@/components/team-one";
import AboutTwo from "@/components/about-two";
import { graphql, useStaticQuery } from "gatsby"


const About = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        name
        specialistsIntro{
          article
          noun
          text
        }
        specialists {
          photo
          name
          position
          slug
        }
        welcome {
          pitch
          storeImg
          teamImg
          content
        }
      }
    }
  `)
  const { welcome, name, specialists, specialistsIntro } = data.pharmacy
  return (
    <MenuContextProvider>
      <Layout PageTitle="Nosotros">
        <HeaderOne />
        <PageHeader title="Nosotros"  altImage={welcome.storeImg} />
        <AboutTwo tenantName={name} welcome={welcome}/>
        <TeamOne extraClassName="section_border" specialists={specialists} specialistsIntro={specialistsIntro}/>
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default About;
